<template>
    <div class="page_container">
        <h1 class="title">{{ page_document().title }}</h1>
        <div v-for="(bloc, indexBloc) in page_document().body" :key="indexBloc">
            <h2 class="sub_title">{{ bloc.h2 }}</h2>
            <span v-for="(div, indexDiv) in bloc.div" :key="indexDiv">
                <p v-if="div.p">{{ div.p }}</p>
                <ul v-if="div.ul">
                    <li v-for="(li, indexLi) in div.ul" :key="indexLi">{{ li }}</li>
                </ul>
                <h3 class="sub_title_2" v-if="div.h3">{{ div.h3 }}</h3>
                <span v-if="div.div">
                    <span v-for="(div2, indexDiv2) in div.div" :key="indexDiv2">
                        <p v-if="div2.p">{{ div2.p }}</p>
                        <ul v-if="div2.ul">
                            <li v-for="(li, indexLi) in div2.ul" :key="indexLi">{{ li }}</li>
                        </ul>
                    </span>
                </span>
            </span>
        </div>
    </div>
</template>
<script>
import page_doc from "@/json/page_document.json";

export default {
    name: 'Document',
    data(){
        return{
            page_doc: page_doc,
        }
    },
    methods:{
        page_document(){
            return this.page_doc[this.$store.state.codeLangue][this.$route.params.name]
        },
    },

    mounted(){
        console.log(this.page_doc[this.$store.state.codeLangue][this.$route.params.name])
        console.log(this.page_doc[this.$store.state.codeLangue]['mentions_legales'])
        this.$store.state.cliquable=true
    }
}
</script>


<style scoped>
.page_container{
    text-align:left;
    padding-top:200px;
    padding-right: 50px;
    padding-left: 50px;
    max-width: 980px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 100px;
}

.title{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px; 
    text-align: center;
    border-bottom: 1px solid #d2d2d7;
    margin-bottom: 45px;
    padding-bottom: 35px;
}

.sub_title{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

.sub_title_2{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
}

p, ul{
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

/*Pour le style des ul/li */
ul{
    padding-left: 0px;
}

li{
    list-style-type: square;
    margin-left: 20px;
}
@media screen and (max-width: 450px) {
  .title{
    font-size:30px;
  }

  .sub_title{
      font-size: 18px;
  }
}
</style>